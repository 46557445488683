@use 'sass:math';
.pricing {

	.section-inner {
		padding-top: $pricing--padding-t__mobile;
		padding-bottom: $pricing--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $pricing-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $pricing-items--padding__mobile != null ) {
			margin-right: -(math.div($pricing-items--padding__mobile, 2));
			margin-left: -(math.div($pricing-items--padding__mobile, 2));
			margin-top: -(math.div($pricing-items--padding__mobile, 2));

			&:last-of-type {
				margin-bottom: -(math.div($pricing-items--padding__mobile, 2));
			}

			&:not(:last-of-type) {
				margin-bottom: math.div($pricing-items--padding__mobile, 2);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $pricing-item--width;
				max-width: $pricing-item--width;
				@if ( $pricing-items--padding__mobile != null ) {
					padding-left: math.div($pricing-items--padding__mobile, 2);
					padding-right: math.div($pricing-items--padding__mobile, 2);
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $pricing-item--width;
		max-width: $pricing-item--width;
		@if ( $pricing-items--padding__mobile != null ) {
			padding: math.div($pricing-items--padding__mobile, 2);
		}
	}

	.tiles-item-inner {
		padding-top: $pricing-item--inner-padding-v;
		padding-bottom: $pricing-item--inner-padding-v;
		padding-left: $pricing-item--inner-padding-h;
		padding-right: $pricing-item--inner-padding-h;
	}
}

.pricing-item-content {
    flex-grow: 1;
    width: 100%;
}

ul.pricing-item-features-list {

	li {
		display: flex;
		align-items: center;
		margin-bottom: math.div($spacing--shared-v, 2);
	}
}

.pricing-switcher,
.pricing-slider {
    margin-bottom: 48px;
}

.pricing-slider {
    max-width: $pricing-slider--max-w;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    input {
        width: 100%;
    }

    .pricing-slider-value {
        position: absolute;
        @include font-size(xxs);
        @include font-weight(label);
        color: color(low-contrast);
        margin-top: 8px;
        --thumb-size: #{$range-thumb-size};       
    }
}

.invert-color {

    .pricing-slider-value {
        color: color(low-contrast-inverse);
    }    
}

@include media( '>medium' ) {

	.pricing {

		.section-inner {
			padding-top: $pricing--padding-t__desktop;
			padding-bottom: $pricing--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $pricing-header--padding__desktop;
		}

		@if ( $pricing-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -(math.div($pricing-items--padding__desktop, 2));
				margin-left: -(math.div($pricing-items--padding__desktop, 2));
				margin-top: -(math.div($pricing-items--padding__desktop, 2));

				&:last-of-type {
					margin-bottom: -(math.div($pricing-items--padding__desktop, 2));
				}

				&:not(:last-of-type) {
					margin-bottom: math.div($pricing-items--padding__desktop, 2);
				}

				&.push-left {

					&::after {
						padding-left: math.div($pricing-items--padding__desktop, 2);
						padding-right: math.div($pricing-items--padding__desktop, 2);
					}
				}
			}

			.tiles-item {
				padding: math.div($pricing-items--padding__desktop, 2);
			}
		}
    }
    
    .pricing-switcher,
    .pricing-slider {
        margin-top: -32px;
		margin-bottom: 64px;
	}
}
