@use 'sass:math';
.tiles-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
	margin-right: -(math.div($tiles-items--padding__mobile, 2));
	margin-left: -(math.div($tiles-items--padding__mobile, 2));
	margin-top: -(math.div($tiles-items--padding__mobile, 2));

	&:last-of-type {
		margin-bottom: -(math.div($tiles-items--padding__mobile, 2));
	}

	&:not(:last-of-type) {
		margin-bottom: math.div($tiles-items--padding__mobile, 2);
	}

	&.push-left {

		&::after {
			content: '';
			flex-basis: $tiles-item--width;
			max-width: $tiles-item--width;
			box-sizing: content-box;
			padding-left: math.div($tiles-items--padding__mobile, 2);
			padding-right: math.div($tiles-items--padding__mobile, 2);
			height: 0;
		}
	}
}

.tiles-item {
	flex-basis: $tiles-item--width;
	max-width: $tiles-item--width;
	box-sizing: content-box;
	padding: math.div($tiles-items--padding__mobile, 2);

	* {
		box-sizing: border-box;
	}
}

.tiles-item-inner {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 100%;
	padding: $tiles-item--inner-padding-v $tiles-item--inner-padding-h;
}

@if ( $tiles-items--padding__desktop != null ) {

	@include media( '>medium' ) {

		.tiles-wrap {
			margin-right: -(math.div($tiles-items--padding__desktop, 2));
			margin-left: -(math.div($tiles-items--padding__desktop, 2));
			margin-top: -(math.div($tiles-items--padding__desktop, 2));

			&:last-of-type {
				margin-bottom: -(math.div($tiles-items--padding__desktop, 2))s;
			}

			&:not(:last-of-type) {
				margin-bottom: (math.div($tiles-items--padding__desktop, 2));
			}

			&.push-left {

				&::after {
					padding-left: (math.div($tiles-items--padding__desktop, 2));
					padding-right: (math.div($tiles-items--padding__desktop, 2));
				}
			}
		}

		.tiles-item {
			padding: (math.div($tiles-items--padding__desktop, 2));
		}
	}
}
